import React, { useState } from 'react'
import { HeroIcons } from '../lib/utils/heroIcons'
import routes from '../config/routes'
import LanguageSelect from './LanguageSelect'
import CurrencySelect from './CurrencySelect'
import { useTranslation } from 'next-i18next'
import { useAuth } from '../providers/AuthProvider'
import { NavigationItem, NavigationPages } from '../types/Navigation'
import { Popover } from '@headlessui/react'
import { classNames } from '../lib/utils/classNames'
import Button, { buttonHierarchy, buttonShape } from './Button'
import Link from 'next/link'

interface MobileBarProps {
  navigation: NavigationItem[]
}

export default function Mobilebar({ navigation }: MobileBarProps) {
  const { t } = useTranslation('common')
  const { isLoggedIn, logout } = useAuth()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const settingsNav: NavigationItem = {
    name: t('navigation.settings'),
    href: '',
    icon: HeroIcons.Cog6ToothIcon,
    id: NavigationPages.SETTINGS,
  }

  const loginNav: NavigationItem = {
    name: t('navigation.login'),
    href: routes.login,
    icon: HeroIcons.ArrowRightOnRectangleIcon,
    id: NavigationPages.LOGIN,
  }

  const homeNav = navigation.find((item) => item.id === NavigationPages.HOME)
  const dashboardNav = navigation.find(
    (item) => item.id === NavigationPages.DASHBOARD,
  )
  const storeNav = navigation.find((item) => item.id === NavigationPages.STORE)
  const accountNav = navigation.find(
    (item) => item.id === NavigationPages.ACCOUNT,
  )

  if (!homeNav || !dashboardNav || !accountNav || !storeNav) {
    return <></>
  }

  const mainButton = (navItem: NavigationItem): React.JSX.Element => {
    return (
      <div className="relative w-full">
        <Link
          href={navItem.href}
          className="absolute -top-5 left-1/2 z-30 aspect-square h-auto w-14 -translate-x-1/2 rounded-full bg-purpleGradient"
        >
          <div className="flex h-full w-full items-center">
            <navItem.icon className="mx-auto h-10 w-10 text-xs text-white" />
          </div>
        </Link>
      </div>
    )
  }

  const secondaryButton = (navItem: NavigationItem): React.JSX.Element => {
    return (
      <Link href={navItem.href} className="w-full">
        <navItem.icon className="mx-auto h-8 w-8 text-xs text-neutralBlack/90" />
        <p className="text-center text-xs font-semibold text-neutralBlack/80">
          {navItem.name}
        </p>
      </Link>
    )
  }

  const loginButton = (navItem: NavigationItem): React.JSX.Element => {
    return (
      <Link href={navItem.href} className="w-full">
        <navItem.icon className="mx-auto h-8 w-8 text-xs text-neutralBlack/90" />
        <p className="text-center text-xs font-semibold text-neutralBlack/80">
          {navItem.name}
        </p>
      </Link>
    )
  }

  const settingsButton = (navItem: NavigationItem): React.JSX.Element => (
    <Popover className="relative flex focus:outline-none">
      {({ open }) => {
        setIsPopoverOpen(open)
        return (
          <>
            <Popover.Button className="w-full">
              <navItem.icon className="mx-auto h-8 w-8 text-xs text-neutralBlack/90" />
              <p className="text-center text-xs font-semibold text-neutralBlack/80">
                {navItem.name}
              </p>
            </Popover.Button>

            <Popover.Panel
              className={classNames(
                isLoggedIn ? 'h-52' : 'h-40',
                'absolute -right-[9px] bottom-[110%] z-20 w-screen rounded-t-xl border-t border-gray-200 bg-gray-50 px-3 pt-3',
              )}
            >
              <div>
                <p className="mb-4 text-xl font-bold text-neutralBlack">
                  {t('topbar.globalSettings.title')}
                </p>

                <ul className="-mx-5 -my-2 select-none">
                  <li className="flex cursor-pointer items-center justify-between bg-white/5 px-5 py-2">
                    <div className="font-semibold text-neutralBlack">
                      <p className="font-light">
                        {t('topbar.globalSettings.language')}
                      </p>
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <LanguageSelect />
                      </div>
                    </div>
                  </li>
                  <li className="flex cursor-pointer items-center justify-between px-5 py-2">
                    <div className="font-semibold text-neutralBlack">
                      <div className="flex items-center gap-1">
                        <p className="font-light">
                          {t('topbar.globalSettings.currency')}
                        </p>
                      </div>
                    </div>
                    <CurrencySelect />
                  </li>

                  {isLoggedIn && (
                    <li className="flex cursor-pointer items-center justify-between px-5 py-2">
                      <div></div>
                      <Button
                        hierarchy={buttonHierarchy.secondary}
                        shape={buttonShape.rectangle}
                        onClick={logout}
                        className="px-3"
                      >
                        <p className="pr-2 font-normal">
                          {t('navigation.logout')}
                        </p>
                        <HeroIcons.ArrowRightOnRectangleIcon
                          className="mx-auto h-8 w-8 shrink-0 hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
            </Popover.Panel>
          </>
        )
      }}
    </Popover>
  )

  return (
    <div
      className={classNames(
        isPopoverOpen ? 'bg-gray-50' : 'bg-transparent',
        'flex h-[8dvh] items-center',
      )}
    >
      <div
        className={
          'border-t-gray-thin standalone-mobilebar-padding-bottom w-full border border-t-2 border-neutralGray/50 bg-white p-1 px-3 py-[10px]'
        }
      >
        <div className="grid h-full w-full grid-cols-5 gap-1">
          <div>{secondaryButton(storeNav)}</div>
          <div>{secondaryButton(dashboardNav)}</div>
          <div>{mainButton(homeNav)}</div>
          <div>
            {isLoggedIn ? secondaryButton(accountNav) : loginButton(loginNav)}
          </div>
          <div>{settingsButton(settingsNav)}</div>
        </div>
      </div>
    </div>
  )
}
