export interface NavigationItem {
  name: string
  href: string
  current?: string
  icon: any
  id: NavigationPages
}

export enum NavigationPages {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  STORE = 'store',
  ACCOUNT = 'account',
  SETTINGS = 'settings',
  LOGIN = 'login',
}
