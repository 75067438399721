import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Mobilebar from './Mobilebar'
import Topbar from './Topbar'
import { Toaster } from 'react-hot-toast'
import AlertsConsumer from './AlertsConsumer'
import routes from '../config/routes'
import { HeroIcons } from '../lib/utils/heroIcons'
import { useTranslation } from 'next-i18next'
import { NavigationItem, NavigationPages } from '../types/Navigation'
import { classNames } from '../lib/utils/classNames'

type LayoutProps = {
  children: React.ReactNode
  topbarComponent?: React.JSX.Element
  showTopbar?: boolean
  customBottomBar?: React.JSX.Element
}

export default function LayoutWithSidebar({
  children,
  topbarComponent,
  showTopbar = true,
  customBottomBar,
}: LayoutProps) {
  const { t } = useTranslation('common')

  const [sidebarState, setSidebarState] = useState(false)

  const [mobilebarState, setMobilebarState] = useState<boolean>(false)

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const navigation: NavigationItem[] = [
    {
      name: t('navigation.home'),
      href: routes.index,
      icon: HeroIcons.HomeIcon,
      id: NavigationPages.HOME,
    },
    {
      name: t('navigation.dashboard'),
      href: routes.dashboard,
      icon: HeroIcons.Squares2X2Icon,
      id: NavigationPages.DASHBOARD,
    },
    {
      name: t('navigation.store'),
      href: routes.properties,
      icon: HeroIcons.BuildingStorefrontIcon,
      id: NavigationPages.STORE,
    },
    {
      name: t('navigation.account'),
      href: routes.account.profile,
      current: routes.account.index,
      icon: HeroIcons.UserIcon,
      id: NavigationPages.ACCOUNT,
    },
  ]
  const updateByScreenSize = () => {
    const sidebarBreakpoint = 1280
    const sidebarBreakpointOrLess = window.innerWidth <= sidebarBreakpoint
    if (sidebarBreakpointOrLess) {
      setSidebarState(false)
    }
    const mobilebarBreakpoint = 640
    const mobilebarBreakpointOrLess = window.innerWidth >= mobilebarBreakpoint
    setMobilebarState(!mobilebarBreakpointOrLess)
  }

  useEffect(() => {
    updateByScreenSize()
    window.addEventListener('resize', updateByScreenSize)
    setIsLoaded(true)
    return () => {
      window.removeEventListener('resize', updateByScreenSize)
    }
  }, [])

  const desktopLayout = () => {
    return (
      <>
        <Toaster
          toastOptions={{
            success: {
              style: {
                border: '1px solid green',
              },
            },
            error: {
              style: {
                border: '1px solid red',
              },
            },
          }}
        />

        <main className="relative flex h-screen">
          <div className="h-full">
            <Sidebar
              setSidebarState={setSidebarState}
              sidebarState={sidebarState}
              navigation={navigation}
            />
          </div>
          <div className="flex w-full flex-col overflow-scroll px-12 pb-5 xl:px-32">
            {showTopbar && <Topbar component={topbarComponent} />}
            {children}
          </div>
        </main>
      </>
    )
  }

  const mobileLayout = () => {
    return (
      <>
        <Toaster
          toastOptions={{
            success: {
              style: {
                border: '1px solid green',
              },
            },
            error: {
              style: {
                border: '1px solid red',
              },
            },
          }}
        />

        <div className="flex h-[91dvh] flex-col">
          {topbarComponent && (
            <div className="sticky top-0 z-20 border-b bg-white px-4 py-3">
              {topbarComponent}
            </div>
          )}
          <main
            className={classNames(
              'flex-grow overflow-y-scroll px-4 pt-4 ',
              customBottomBar ? 'pb-16' : 'pb-4',
            )}
          >
            {children}
          </main>
          <div className="fixed bottom-0 z-50 w-full">
            {customBottomBar ? (
              customBottomBar
            ) : (
              <Mobilebar navigation={navigation} />
            )}
          </div>
        </div>
      </>
    )
  }

  const setLayout = (mobileView: boolean) => {
    if (!isLoaded) {
      return <>{children}</>
    }

    return mobileView ? mobileLayout() : desktopLayout()
  }

  return (
    <>
      <AlertsConsumer />
      {setLayout(mobilebarState)}
    </>
  )
}
