import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { HeroIcons } from '../lib/utils/heroIcons'
import Button, { buttonHierarchy, buttonShape } from './Button'
import { Currency } from '../types/Currency'
import { classNames } from '../lib/utils/classNames'

export default function CurrencySelect() {
  const currencies: Currency[] = [
    { name: 'Euro (EUR)', icon: HeroIcons.CurrencyEuroIcon },
    // { name: 'US Dollar (USD)', icon: HeroIcons.CurrencyDollarIcon },
  ]

  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    currencies[0],
  )

  const handleSelectCurrency = (currency: Currency) => {
    setSelectedCurrency(currency)
  }

  return (
    <div className="w-40 cursor-pointer">
      <Menu>
        <div className="relative">
          <Menu.Button className="relative flex h-full w-full cursor-default items-center rounded-2xl border border-neutralGray/50 bg-white px-2 py-1 text-sm text-neutralBlack shadow-2xl hover:cursor-pointer">
            <div className="flex items-center">
              <selectedCurrency.icon className="h-5 w-5 text-neutralBlack" />
            </div>
            <p className="ml-1">{selectedCurrency.name}</p>
            <span className="pointer-events-none ml-auto">
              <HeroIcons.ChevronDownIcon
                className="h-5 w-5 text-neutralBlack/50"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="absolute top-9 z-40 mt-1 max-h-60 w-full overflow-y-scroll rounded-md border border-neutralGray/50 bg-neutralWhite text-sm shadow-lg">
              {currencies.map((currency) => (
                <Menu.Item key={currency.name} as={Fragment}>
                  <Button
                    hierarchy={buttonHierarchy.custom}
                    shape={buttonShape.rectangle}
                    className={classNames(
                      selectedCurrency.name == currency.name ? 'font-bold' : '',
                      'flex items-center px-3 py-1.5',
                    )}
                    onClick={() => handleSelectCurrency(currency)}
                  >
                    <span className="pr-3 text-neutralBlack">
                      <currency.icon className="h-5 w-5 text-neutralBlack" />
                    </span>
                    <span>{currency.name}</span>
                  </Button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  )
}
